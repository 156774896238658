import React from "react";
import { MainPage } from "./pages/Main";

export const App = () => {
  return (
    <div className="App">
      <MainPage />
    </div>
  );
};
