export const PRODUCTS_DATA = [
  {
    name: "Нямушка",
    flavour: "с фуа-гра",
    description: "Печень утки разварная с артишоками.",
    portion: 10,
    gift: "мышь в подарок",
    comment: "",
    weight: 0.5,
    id: 0,
    selected: false,
    disabled: false,
  },
  {
    name: "Нямушка",
    flavour: "с рыбой",
    description: "Головы щучьи с чесноком да свежайшая сёмгушка.",
    portion: 40,
    gift: "2 мыши в подарок",
    comment: "",
    weight: 2,
    id: 1,
    selected: true,
    disabled: false,
  },
  {
    name: "Нямушка",
    flavour: "с курой",
    description: "Филе из цыплят с трюфелями в бульоне.",
    portion: 100,
    gift: "5 мышей в подарок",
    comment: "заказчик доволен",
    weight: 5,
    id: 2,
    selected: false,
    disabled: true,
  },
];
